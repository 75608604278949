
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums.ts";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const user = store.getters.currentUser;
    const model = reactive({
      nome: user.nome,
      imagem: user.imagem,
      email: user.email,
    });

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => {
          router.push({ name: "login" });
      });
    };

    return {
      signOut,
      model,
    };
  },
});
